import Vue from 'vue'

// 用于控制浏览器前进后退 使用keep-alive
// eslint-disable-next-line no-unused-vars
import Navigation from 'vue-navigation'

import FastClick from 'fastclick'

import App from './App.vue'
import store from './store'
import router from './router'

// 页顶进度条
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'

import './style/global.less'
import '@/assets/iconfont/iconfont.css'

// 安装基础组件 与自定义组件
import BaseComponent from './base'
// import CustomComponent from './components'
// import _ from 'lodash'
// Vue.prototype._ = _

// import * as Sentry from '@sentry/vue'
// import { Integrations } from '@sentry/tracing'
// // process.env.NODE_ENV === "production" && Sentry.init({
// Sentry.init({
//   Vue,
//   dsn:
//     'https://b9dc25bcd6724b31b37ed9281a870aac@o528154.ingest.sentry.io/5645327',
//   integrations: [new Integrations.BrowserTracing()],
//   release: 'test@1.0.2',
//   logErrors: true,

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0
// })

const splitSentry = () => import('@sentry/tracing')
const splitSentryv = () => import('@sentry/vue')
splitSentry().then(({ Integrations }) => {
  splitSentryv().then(Sentry => {
    Sentry.init({
      Vue,
      dsn:
        'https://b9dc25bcd6724b31b37ed9281a870aac@o528154.ingest.sentry.io/5645327',
      integrations: [new Integrations.BrowserTracing()],
      // release: 'test@1.0.2',
      release: process.env.RELEASE_VERSION,
      logErrors: true,
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0
    })
    Vue.prototype.$Sentry = Sentry
  })
})

Vue.use(BaseComponent, {})
// Vue.use(CustomComponent, {})

import { Icon, Toast, Lazyload } from 'vant'

Vue.use(Icon)
Vue.use(Toast)

// import { Lazyload } from 'vant'
Vue.use(Lazyload, {
  lazyComponent: true
})

// const splitvant = () => import('vant')
// // invoked as a response to some user interaction
// splitvant().then(({ Icon, Toast, Lazyload }) => {
//   Vue.use(Icon)
//   Vue.use(Toast)

//   // import { Lazyload } from 'vant'
//   Vue.use(Lazyload, {
//     lazyComponent: true
//   })
// })

// import VeeValidate, { Validator } from 'vee-validate'
// import zhCN from 'vee-validate/dist/locale/zh_CN'

// Validator.localize('zh-CN', zhCN)
// Vue.use(VeeValidate, {
//   errorBagName: 'errorBags',
//   fieldsBagName: 'fieldBags',
//   locale: 'zh-CN'
// })

// Vue.use(Lazyload, {
//   preLoad: 1.3,
//   error: require('@/assets/images/goods_default.png'),
//   loading: require('@/assets/images/goods_default.png'),
//   attempt: 1,
//   listenEvents: ['scroll'],
//   lazyComponent: true
// })

Vue.config.productionTip = false

// 处理点击事件延迟300ms问题
FastClick.attach(document.body)

// 用于控制浏览器前进后退缓存
Vue.use(Navigation, {
  router,
  store,
  keyName: 'VN'
})

import qStore from './store/$q.mixin'
// 引入对store的简化写法
Vue.prototype.$store = store
Vue.mixin(qStore)

// NProgress.inc(0.2)
// NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false })

// router.beforeEach((to, from, next) => {
//   NProgress.start()
//   next()
// })

// router.afterEach(() => {
//   NProgress.done()
// })

// 开发环境下面使用vConsole进行调试
// TODO: 正式调试的时候开启VConsole
// if (process.env.NODE_ENV === 'development') {
//   const VConsole = require('vconsole')
//   new VConsole()
// }

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
