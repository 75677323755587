// import asyncLoader from 'core/async-loader'
// const tab_cart = asyncLoader('order/tabbar-cart')
// const PlaceOrderEntity = asyncLoader('order/place-order-entity')
// const PlaceOrderVirtual = asyncLoader('order/place-order-virtual')
// const Payment = asyncLoader('order/payment')
// const PaymentStatus = asyncLoader('order/payment-status')

// const Tabbar = () =>
//   import(/* webpackChunkName: "Tabbar" */ '@/vue/components/Tabbar/')
import Tabbar from '../../components/Tabbar'
// import Indent from '../../views/indent/indent'
const Indent = () => import('../../views/indent/indent')

export default [
  {
    path: '/indent',
    name: 'Indent',
    meta: {
      login: true,
      keepAlive: true,
      tabIndex: 3,
      title: '订单'
    },
    components: { default: Indent, tabbar: Tabbar }
  }
  //   {
  //     path: '/order/placeOrderEntity',
  //     name: 'placeOrderEntity',
  //     component: PlaceOrderEntity
  //   },
  //   {
  //     path: '/order/placeOrderVirtual',
  //     name: 'placeOrderVirtual',
  //     component: PlaceOrderVirtual
  //   },
  //   {
  //     path: '/order/payment',
  //     name: 'payment',
  //     component: Payment
  //   },
  //   {
  //     path: '/order/payment/:status',
  //     name: 'paymentStatus',
  //     component: PaymentStatus,
  //     props: true
  //   }
]
