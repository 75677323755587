<template>
  <div
    class="md_field"
    :class="{ md_field_hasIcon: !!icon, md_field_isError: isError }"
  >
    <van-icon
      v-if="icon"
      class-prefix="my-icon"
      :name="icon"
      class="md_feld_icon"
    />
    <div class="md_field_control">
      <input :type="type" v-bind="$attrs" :value="value" v-on="listeners" />
    </div>
    <div>
      <slot name="rightIcon">
        <van-icon
          v-show="value"
          class-prefix="my-icon"
          :name="rightIcon"
          @click="rightClick"
        />
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MdField',

  props: {
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    rightIcon: {
      type: String,
      default: 'text'
    },
    icon: {
      type: String,
      default: 'text'
    },
    isError: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput
      }
    }
  },

  methods: {
    onInput(event) {
      this.$emit('input', event.target.value)
    },
    rightClick(event) {
      this.$emit('right-click', event)
    }
  }
}
</script>

<style scoped>
.md_field {
  position: relative;
  box-sizing: border-box;
  display: table;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  background-color: #ffffff;
  border: 2px solid gray;
  border-radius: 5px;
}

.md_field > div {
  display: table-cell;
}

.md_field > .md_field_control {
  box-sizing: border-box;
  padding-right: 10px;
}

.md_field_control input {
  width: 100%;
  height: 24px;
  margin: 5px 0;
  font-size: 14px;
  line-height: 18px;
  border: 0;
}

.md_feld_icon {
  position: absolute;
  top: 50%;
  left: 15px;
  font-size: 16px;
  transform: translate(0, -50%);
}

.md_field_hasIcon {
  padding-left: 40px;
}

.md_field_isError {
  color: red;
  background-color: #fcf5f5;
  border: 1px solid red;
}

.md_field_isError input {
  color: red;
  background-color: #fcf5f5;
}

.md_field_isError input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fcf5f5 inset !important;
}
</style>
