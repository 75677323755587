import Vue from 'vue'
import Vuex from 'vuex'
import { getLocalStorage, setLocalStorage } from '@/utils/local-storage'
// eslint-disable-next-line no-unused-vars
import { getuserinfo } from '@/api/login'

Vue.use(Vuex)

let localData = {}

try {
  // 尝试获取本地是否存在localData变量，第一次启动APP时是不存在的P时是不存在的
  let temp = getLocalStorage('localData')
  if (temp) {
    localData = JSON.parse(temp['localData'])
    // console.log(localData)
  }
} catch (e) {
  console.log('getLocalStorage_err')
}

// 需要永久存储，且下次APP启动需要取出的，在state中的变量名
let saveStateKeys = ['hasLogin', 'token', 'configs', 'logindata']

// 保存变量到本地存储中
const savelocalData = function(key, value) {
  // 判断变量名是否在需要存储的数组中
  if (saveStateKeys.indexOf(key) != -1) {
    // 获取本地存储的localData对象，将变量添加到对象中
    let tmp = getLocalStorage(['localData'])
    // 第一次打开APP，不存在localData变量，故放一个{}空对象
    tmp = tmp['localData'] ? JSON.parse(tmp['localData']) : {}
    tmp[key] = value
    // 执行这一步后，所有需要存储的变量，都挂载在本地的localData对象中
    setLocalStorage({ localData: JSON.stringify(tmp) })
  }
}

export default new Vuex.Store({
  state: {
    // 如果上面从本地获取的localData对象下有对应的属性，就赋值给state中对应的变量
    // 下面是需要保存到本地的数据
    hasLogin: localData && localData.hasLogin ? localData.hasLogin : false,
    token: localData && localData.token ? localData.token : '',
    // eslint-disable-next-line prettier/prettier
    configs: localData && localData.configs? localData.configs : { autologin: false, saveLogin: false },
    // eslint-disable-next-line prettier/prettier
    logindata: localData && localData.logindata? localData.logindata : { user: '', pwd: '' },
    // 下面无需保存到本地的数据localData.qs_version方式
    version: '0.3.1',
    loginStatus: 0,
    UserProfile: {},
    goodsList: [],
    tagsList: [],
    stoken: '',
    deliveGoodsList: [],
    checkLoginFlag: false
  },
  mutations: {
    $qStore(state, payload) {
      // 判断是否多层级调用，state中为对象存在的情况，诸如user.info.score = 1
      let nameArr = payload.name.split('.')
      let saveKey = ''
      let len = nameArr.length
      if (nameArr.length >= 2) {
        let obj = state[nameArr[0]]
        for (let i = 1; i < len - 1; i++) {
          obj = obj[nameArr[i]]
        }
        obj[nameArr[len - 1]] = payload.value
        saveKey = nameArr[0]
      } else {
        // 单层级变量，在state就是一个普通变量的情况
        state[payload.name] = payload.value
        saveKey = payload.name
      }
      // 保存变量到本地，见顶部函数定义
      savelocalData(saveKey, state[saveKey])
    },
    mSaveUserProfile(state, data) {
      state.UserProfile = data
    },
    mClearUserProfile(state) {
      state.UserProfile = {}
    },
    mSaveGoodsList(state, data) {
      state.goodsList = data
    },
    mSaveDeliveGoodsList(state, data) {
      state.deliveGoodsList = data
    },
    setCheckLogin(state, flag) {
      state.checkLoginFlag = flag
    },
    saveToken(state, token) {
      state.stoken = token
    },
    loginSuccess(state) {
      state.loginStatus = 1
    },
    loginFail(state) {
      state.loginStatus = 0
    },
    loginOut(state) {
      state.loginStatus = 0
    },
    setTagList(state, list) {
      if (list) {
        state.tagsList = [...list]
      } else {
        // 若有存入localstorage中时可考虑
        // tagsList = getTagsListFromLocalstorage() || []
      }
    }
  },
  getters: {
    getAccessToken: state => {
      return state.stoken === '' ? state.token : state.stoken
    },
    tagsList: state => state.tagsList
  },
  actions: {
    aUpdate(context, payload) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          context.commit('mSaveUserProfile')
          console.log(payload)
          resolve('我完成了')
        }, 1000)
      })
    },
    // eslint-disable-next-line no-unused-vars
    async aUpdateUserProfile(context) {
      console.log('aUpdateUserProfile')
      const res = await getuserinfo()
      if (res.status === 1) {
        // console.info('获取数据成功')
        context.commit('mSaveUserProfile', res.data)
        console.log('getuserinfo', res)
      } else {
        // console.info('获取数据失败')
        throw res
      }
      return res
    },
    loginAction({ commit }, { token, flag }) {
      commit('loginSuccess')
      commit('saveToken', token)
      if (flag) {
        commit('$qStore', {
          name: 'token',
          value: token
        })
      }
      commit('$qStore', {
        name: 'hasLogin',
        value: true
      })
    },
    tokenAction({ commit }, token) {
      commit('$qStore', {
        name: 'token',
        value: token
      })
    },
    loginOutAction({ commit }) {
      commit('loginOut')
      commit('mClearUserProfile')
      commit('$qStore', {
        name: 'token',
        value: ''
      })
      commit('$qStore', {
        name: 'hasLogin',
        value: false
      })
    }
  },
  modules: {}
})
