<template>
  <van-tabbar v-model="active" safe-area-inset-top style="z-index: 1999;">
    <van-tabbar-item
      v-for="(tab, index) in tabbar"
      :key="index"
      :icon="tab.icon"
      :icon-prefix="tab.iconprefix"
      :to="tab.path"
      :dot="tab.dot"
      :info="tab.info"
    >
      {{ tab.name }}
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant'

export default {
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  data() {
    return {
      active: 0,
      tabbar: [
        {
          name: '我的',
          path: '/',
          pathName: 'Home',
          icon: 'username',
          iconprefix: 'my-icon',
          dot: false,
          info: ''
        },
        {
          name: '库存',
          path: '/inventory',
          pathName: 'Inventory',
          icon: 'kucun',
          iconprefix: 'my-icon',
          dot: false,
          info: ''
        },
        {
          name: '订单',
          path: '/indent',
          pathName: 'Indent',
          icon: 'dingdan',
          iconprefix: 'my-icon',
          dot: false,
          info: ''
        },
        {
          name: '派送',
          path: '/delivery',
          pathName: 'Delivery',
          icon: 'paisong',
          iconprefix: 'my-icon',
          dot: false,
          info: ''
        },
        {
          name: '团队',
          path: '/team',
          pathName: 'Team',
          icon: 'friends',
          iconprefix: 'van-icon',
          dot: false,
          info: ''
        }
      ]
    }
  },
  // TODO:修改tabs的路由切换方式,以使用Navigation的路由缓存
  watch: {
    $route: 'changeActive'
  },

  created() {
    const toName = this.$route.name
    this.setActive(toName)
  },

  methods: {
    changeActive({ name }) {
      this.setActive(name)
    },
    setActive(name) {
      this.tabbar.forEach((tab, i) => {
        if (tab.pathName == name) {
          this.active = i
          return false
        }
      })
    }
  }
}
</script>
