<template>
  <div class="item_card_H_wrap" @click="OnClick">
    <img v-if="rank === 1" class="item_sn" :src="rank1" alt="" />
    <img v-else-if="rank === 2" class="item_sn" :src="rank2" alt="" />
    <div
      v-else-if="rank === 3"
      class="item_sn"
      :style="'background-image: url(' + rank3 + ');'"
    >
      {{ rank }}
    </div>
    <div
      v-else
      class="item_sn"
      :style="'background-image: url(' + rank4 + ');'"
    >
      {{ rank }}
    </div>
    <div class="item_icon">
      <van-image :src="tavatar" @error="imgerror" />
    </div>
    <div class="item_desc">
      <div class="item_desc_main">
        <span
          class="item_desc_main_name"
          :class="skeleton ? 'class_skeleton' : ''"
        >
          {{ namec }}
        </span>
        <span
          class="item_desc_main_amount"
          :class="skeleton ? 'class_skeleton' : ''"
        >
          ￥{{ parseFloat(amount).toFixed(2) }}
        </span>
      </div>
      <div class="item_desc_sub" :class="skeleton ? 'class_skeleton' : ''">
        <span class="item_desc_sub_type">
          {{ typec }}
        </span>
        <span class="item_desc_sub_jointime">加入日期：{{ time }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import defaultperson from '../../assets/avatar_default.png'
import rank1 from '../../assets/rank1.png'
import rank2 from '../../assets/rank2.png'
import rank3 from '../../assets/rank3.png'
import rank4 from '../../assets/rank4.png'
import { Image } from 'vant'
export default {
  name: 'ItemCardPerson',
  components: {
    [Image.name]: Image
  },
  props: {
    goods: {
      type: Object,
      default: () => ({})
    },
    rank: {
      type: Number,
      default: 0
    },
    avatar: {
      type: String,
      default: defaultperson
    },
    name: {
      type: String,
      default: '无名氏'
    },
    time: {
      type: String,
      default: '2020-01-01'
    },
    type: {
      type: String,
      default: '周代理商'
    },
    amount: {
      type: String,
      default: '99999.00'
    },
    skeleton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tavatar: this.avatar,
      defaultperson,
      rank1,
      rank2,
      rank3,
      rank4
    }
  },
  computed: {
    namec() {
      return this.name
    },
    typec() {
      return this.type === null ? '分销商' : this.type
    }
  },
  methods: {
    OnClick() {
      this.$emit('click')
    },
    imgerror() {
      console.log('imgerror')
      this.tavatar = defaultperson
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';
.item_card_H_wrap {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 10px;
  margin: 1px;
  background-color: #ffffff;
}
.item_sn {
  width: 27px;
  height: 24px;
  font-size: 4px;
  line-height: 27px;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 27px 24px;
}
.item_icon {
  position: relative;
  width: 45px;
  height: 45px;
  margin-left: 11px;
  overflow: hidden;
  text-align: center;
  border-radius: 50%;
  img {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
  }
}
.item_desc_main_name {
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.item_desc {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
  margin-left: 20px;
  &_main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: @font-size-normal;
  }
  &_sub {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: @font-size-small;
    color: @gray;
  }
}
.class_skeleton {
  color: #bfbfbf;
  background-color: #bfbfbf;
}
</style>
