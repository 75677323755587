// import store from '@/store'
import { Toast } from 'vant'
import { mapMutations } from 'vuex'
export default {
  components: {
    [Toast.name]: Toast
  },
  methods: {
    ...mapMutations(['setCheckLogin']),
    handleNetError(res) {
      console.log('handleNetError', res)
      if (res.status === undefined) {
        Toast.fail('连接错误，请检查网络连接')
        return
      }
      if (res.status === 0 || res.status === -1) {
        console.info('登录已失效')
        Toast.fail('登录已失效  请重新登陆')
        // store.loginOut('mSaveUserProfile')
        this.$store.dispatch('loginOutAction')
        this.$router.push('/login')
        this.setCheckLogin(true)
      }
    },
    checkLogin(res) {
      if (res.status === 0) {
        console.info('登录已失效')
        Toast.fail('登录已失效  请重新登陆')
        // store.loginOut('mSaveUserProfile')
        this.$store.dispatch('loginOutAction')
        this.$router.push('/login')
        this.setCheckLogin(true)
      }
    }
  }
}
