<template>
  <div class="field_group">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'MdFieldGroup'
}
</script>

<style scoped>
.field_group {
  padding-right: 15px;
  padding-left: 15px;
}

.field_group > div {
  margin-bottom: 15px;
}

.field_group > div:last-child {
  margin-bottom: 0;
}
</style>
