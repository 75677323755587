// import Team from '../../views/team/team'
const Team = () => import('../../views/team/team')
// const Tabbar = () =>
//   import(/* webpackChunkName: "Tabbar" */ '../../components/Tabbar/')
import Tabbar from '../../components/Tabbar'

export default [
  {
    path: '/team',
    name: 'Team',
    meta: {
      keepAlive: true,
      tabIndex: 5,
      title: '团队'
    },
    components: { default: Team, tabbar: Tabbar }
  }
  // {
  //   path: '/user/collect',
  //   name: 'collect',
  //   meta: {
  //     login: true
  //   },
  //   component: UserCollect
  // },
  // {
  //   path: '/user/address',
  //   name: 'address',
  //   meta: {
  //     login: true
  //   },
  //   component: UserAddress
  // },
  // {
  //   path: '/user/address/edit/:addressId',
  //   name: 'address-edit',
  //   props: true,
  //   meta: {
  //     login: true
  //   },
  //   component: UserAddressEdit
  // }
]
