<template>
  <div class="item_card_H_wrap" @click="OnClick">
    <van-checkbox
      v-if="selectable"
      v-model="checked"
      class="item_checkbox"
      @change="change"
    />
    <div class="item_icon">
      <van-image fit="cover" lazy-load class="item_icon" :src="thumb_img">
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
        <template v-slot:error>
          <!-- <van-icon name="photo-fail" size="20" /> -->
          <van-image fit="cover" class="item_icon" :src="defaultgoods" />
        </template>
      </van-image>
    </div>
    <div class="item_desc">
      <div class="item_desc_main">
        <div class="item_desc_main_name">
          {{ goods.goods_name }}
        </div>
        <div class="item_desc_main_pricearea">
          <span v-show="theprice !== undefined" class="item_desc_main_price">
            {{ `￥${theprice}` }}
          </span>
          <span v-if="num !== 0" class="item_desc_main_num">{{
            'X' + num
          }}</span>
        </div>
        <van-icon
          v-if="editable"
          class="item_desc_main_deletebtn"
          name="delete-o"
          @click="deleteClick"
        />
      </div>
      <div class="item_desc_sub">
        <span class="item_desc_sub_type">{{
          `规格：总重${goods.weight / 1000}吨`
        }}</span>
        <van-stepper
          v-if="editable"
          v-model="value"
          theme="round"
          button-size="22"
          disable-input
          :max="goods.num"
          @change="stepChange"
        />
        <span v-else-if="hidestore" class="item_desc_sub_store"></span>
        <span v-else class="item_desc_sub_store">
          库存{{ parseFloat(store).toFixed(2) }}吨
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import defaultgoods from '../../assets/defaultgoods.jpg'
import { Checkbox, Stepper, Icon, Image, Loading } from 'vant'
export default {
  name: 'ItemCardGoods',
  components: {
    [Checkbox.name]: Checkbox,
    [Loading.name]: Loading,
    [Stepper.name]: Stepper,
    [Image.name]: Image,
    [Icon.name]: Icon
  },
  props: {
    goods: {
      type: Object,
      default: () => ({
        num: 0,
        shop_price: '100.00',
        weight: 0,
        goods_name: '无此商品',
        original_img: ''
      })
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    hidestore: {
      type: Boolean,
      default: false
    },
    num: {
      type: Number,
      default: 0
    },
    cartNum: {
      type: Number,
      default: 1
    },
    store: {
      type: Number,
      default: 0
    },
    price: {
      type: String,
      default: '0.00'
    }
  },
  data() {
    return {
      defaultgoods,
      checked: this.selected,
      value: this.cartNum
    }
  },
  computed: {
    theprice() {
      if (this.price !== '0.00') return this.price
      else if (this.goods.shop_price !== undefined)
        return String(this.goods.shop_price)
      return undefined
    },
    thumb_img() {
      if (this.goods.thumb_img !== undefined) return this.goods.thumb_img
      return this.goods.original_img
        ? this.goods.original_img
        : this.defaultgoods
    }
  },
  methods: {
    OnClick() {
      if (this.selectable === true) {
        this.checked = !this.checked
      }
      this.$emit('click', this.goods)
    },
    deleteClick() {
      this.$emit('delete', this.goods)
    },
    change(checked) {
      this.$emit('change', {
        checked: checked,
        id: this.goods.id,
        goods: this.goods
      })
    },
    stepChange(num) {
      this.$emit('stepChange', {
        num: num,
        id: this.goods.id,
        goods: this.goods
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';
.item_card_H_wrap {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 14px;
  margin: 1px;
  background-color: #ffffff;
}
.item_sn {
  width: 27px;
  height: 24px;
  line-height: 27px;
  text-align: center;
}
.item_checkbox {
  flex: 0 0 20px;
  width: 20px;
  margin-right: 14px;
}
.item_icon {
  position: relative;
  width: 70px;
  height: 70px;
  overflow: hidden;
  text-align: center;
  border: 1px solid #dddddd;
  img {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
  }
}
.item_desc {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
  margin-left: 20px;
  &_main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: @font-size-normal;
    &_name {
      width: 163px;
      height: 40px;
      overflow: hidden;
    }
    &_pricearea {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      // justify-content: space-evenly;
    }
    &_price {
      margin-left: auto;
      color: rgb(255, 59, 48);
    }
    &_num {
      font-size: @font-size-small;
      color: @gray-deep;
    }
    &_deletebtn {
      width: 18px;
      height: 18px;
      margin-left: 10px;
      font-size: 18px;
    }
  }
  &_sub {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: @font-size-small;
    color: @gray-deep;
  }
}
</style>
