<template>
  <div class="text-desc text-center bottom_positon"></div>
</template>

<script>
export default {
  name: 'LoginFooter'
}
</script>

<style scoped>
.bottom_positon {
  position: absolute;
  bottom: 30px;
  width: 100%;
}
</style>
