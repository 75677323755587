import { CountUp } from 'countup.js'
// import ICountUp from 'vue-countup-v2'
// import * as Sentry from '@sentry/vue'
export default {
  components: {
    // ICountUp
  },
  data() {
    return {
      options: {
        useEasing: true,
        decimalPlaces: 2,
        duration: 2,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      }
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async numcountup(target, newval, oldval = 0, decimalPlaces = null) {
      let ds = newval.toString().split('.')[1]
      ds = decimalPlaces ? decimalPlaces : ds ? ds.length : 0
      let n1 = new CountUp(target, newval, {
        ...this.options,
        decimalPlaces: ds,
        startVal: oldval
      })
      if (!n1.error) {
        n1.start()
      } else {
        // console.error(n1.error)
      }
    }
  }
}
