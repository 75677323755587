<template>
  <div class="item_card_sotre_wrap" @click="OnClick">
    <van-cell :title="title" :value="status === 0 ? '未派送' : '已派送'" />
    <item-card-goods
      v-for="(item, index) in goodsArray"
      :key="index"
      :goods="item"
      :price="item.goods_price"
      :num="item.goods_num"
      :hidestore="true"
    />
    <div class="item_desc_sub">
      <span class="item_desc_sub_input">收货人：{{ consignee }}</span>
      <span class="item_desc_sub_output">电 话：{{ phone }}</span>
      <span class="item_desc_sub_store"> 收货地址：{{ address }} </span>
    </div>
    <div v-if="status === 0" class="item_card_divider"></div>
    <div v-if="status === 0" class="item_card_delivery_btn_zone">
      <div class="item_card_delivery_btn_zone_btn" @click.stop="btnClick">
        去派送
      </div>
    </div>
  </div>
</template>

<script>
import defaultperson from '../../assets/avatar_default.png'
import { Checkbox, Stepper, Icon, Divider, Cell } from 'vant'
import ItemCardGoods from '../item-card-goods'
export default {
  name: 'ItemCardDelivery',
  components: {
    [ItemCardGoods.name]: ItemCardGoods,
    [Checkbox.name]: Checkbox,
    [Stepper.name]: Stepper,
    [Divider.name]: Divider,
    [Cell.name]: Cell,
    [Icon.name]: Icon
  },
  props: {
    title: {
      type: String,
      default: '20201021003201'
    },
    status: {
      type: Number,
      default: 0
    },
    consignee: {
      type: String,
      default: ''
    },
    goodsArray: {
      type: Array,
      default: () => []
    },
    phone: {
      type: String,
      default: '13000000000'
    },
    address: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: false
    },
    num: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      defaultperson,
      checked: false,
      value: 1
    }
  },
  methods: {
    OnClick() {
      if (this.status === 0) this.$emit('btnClick')
      else this.$emit('Click')
    },
    btnClick() {
      this.$emit('btnClick')
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';
.item_card_sotre_wrap {
  display: flex;
  flex-direction: column;
  padding: 0 0 10px;
  margin: 14px;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 6px;
}
.item_desc_sub {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 58px;
  padding: 3px 14px;
  font-size: @font-size-small;
  color: #676767;
  background-color: #ffffff;
  &_store {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
// .item_card_divider {
//   height: 50px;
//   padding: 0 14px;
//   margin: 0 14px;
//   color: #000000;
//   background-color: #f9f9f9;
//   border-color: #ffffff;
// }
.item_card_delivery_btn_zone {
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
  margin: 0 14px;
  border-top: 1px solid #f5f5f5;
  &_btn {
    width: 80px;
    height: 28px;
    line-height: 28px;
    color: #ffffff;
    text-align: center;
    background: #31ae65;
    border-radius: 99px;
    opacity: 1;
  }
}
</style>
