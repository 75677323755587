import Tabbar from '../../components/Tabbar'
import Home from '../../views/home'
const SalesRankEntity = () => import('../../views/home/salesrank')
const ChangePSWEntity = () => import('../../views/home/changepsw.vue')
const IncomeEntity = () => import('../../views/home/income/')
const detailEntity = () => import('../../views/home/income/teambuild/detail')
const applyEntity = () => import('../../views/home/income/teambuild/apply')
const costEntity = () => import('../../views/home/income/teambuild/cost')

export default [
  {
    path: '/home',
    name: 'Home',
    components: {
      default: Home,
      tabbar: Tabbar
    },
    meta: {
      keepAlive: true,
      tabIndex: 1,
      title: '首页'
    }
  },
  {
    path: '/home/salesrank',
    name: 'SalesRankEntity',
    component: SalesRankEntity,
    meta: {
      title: '销量排行'
    }
  },
  {
    path: '/home/changepsw',
    name: 'ChangePSW',
    component: ChangePSWEntity,
    meta: {
      title: '修改密码'
    }
  },
  {
    path: '/home/income',
    name: 'incomeEntity',
    component: IncomeEntity,
    meta: {
      title: '收益明细'
    }
  },
  {
    path: '/home/income/apply',
    name: 'applyEntity',
    component: applyEntity,
    meta: {
      title: '团建申请'
    }
  },
  {
    path: '/home/income/detail/:id/cost',
    name: 'costEntity',
    component: costEntity,
    props: route => {
      return {
        id: route.params.id,
        detail: route.params.detail,
        actualMoney: route.params.actualMoney
      }
    },
    meta: {
      title: '费用申请'
    }
  },
  {
    path: '/home/income/detail',
    name: 'detailEntity',
    component: detailEntity,
    props: route => {
      return {
        id: route.query.id,
        detail: route.params.detail
      }
    },
    meta: {
      title: '详情'
    }
  }
]
