export default {
  methods: {
    async asyncWait(time) {
      await new Promise(resolve => {
        setTimeout(() => {
          resolve('')
        }, time)
      })
    }
  }
}
