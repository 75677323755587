import Delivery from '../../views/delivery/delivery'
const Tabbar = () =>
  import(/* webpackChunkName: "Tabbar" */ '../../components/Tabbar/')
const DispatchEntity = () => import('@/views/delivery/dispatch/')

export default [
  {
    path: '/delivery',
    name: 'Delivery',
    meta: {
      keepAlive: true,
      tabIndex: 4,
      title: '派送'
    },
    components: { default: Delivery, tabbar: Tabbar }
    // },
    // {
    //   path: '/order/placeOrderEntity',
    //   name: 'placeOrderEntity',
    //   component: PlaceOrderEntity
  },
  {
    path: '/delivery/:id/dispatch',
    name: 'dispatch',
    component: DispatchEntity,
    props: route => {
      return {
        id: route.params.id,
        detail: route.params.detail,
        editable: true
      }
    },
    meta: {
      title: '派送'
    }
  },
  {
    path: '/delivery/:id/detail',
    name: 'deliveryDetail',
    component: DispatchEntity,
    props: route => {
      return {
        id: route.params.id,
        detail: route.params.detail
      }
    },
    meta: {
      title: '派送详情'
    }
  }
]
