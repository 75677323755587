import { get, post } from '@/utils/request'

export const getDemoData = (data = {}) => {
  return get('home/demo/api', data)
  // return new Promise(resolve => {
  //   setTimeout(() => {
  //     resolve({
  //       success: true,
  //       data: {
  //         name: '11',
  //       }
  //     })
  //   }, 2000)
  // })
}

// 用户登录接口
export const postLogin = (data = {}) => {
  return post('login', data)
}

/**
 * 获取用户数据
 * @param {String} data 参数
 */
export const getuserinfo = (data = {}) => {
  return get('getuserinfo', data)
}
