<template>
  <div class="item_card_sotre_wrap" @click="OnClick">
    <item-card-goods :hidestore="true" :goods="goods" />
    <div class="item_desc_sub">
      <span class="item_desc_sub_input">
        入库：{{ parseFloat(storeIn).toFixed(3) }}吨
      </span>
      <span class="item_desc_sub_output">
        出库：{{ parseFloat(storeOut).toFixed(3) }}吨
      </span>
      <span class="item_desc_sub_store">
        库存：{{ parseFloat(store).toFixed(3) }}吨
      </span>
    </div>
  </div>
</template>

<script>
import defaultperson from '../../assets/avatar_default.png'
import { Checkbox, Stepper, Icon } from 'vant'
import ItemCardGoods from '../item-card-goods'
export default {
  name: 'ItemCardGoodsStore',
  components: {
    [ItemCardGoods.name]: ItemCardGoods,
    [Checkbox.name]: Checkbox,
    [Stepper.name]: Stepper,
    [Icon.name]: Icon
  },
  props: {
    goods: {
      type: Object,
      default: () => ({
        id: 1,
        agent_id: 2,
        goods_id: 134,
        num: 97,
        isdel: 0,
        shop_price: '100.00',
        weight: 25,
        goods_name: '经销商测试商品-桉树肥',
        img: ''
      })
    },
    selectable: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    store: {
      type: Number,
      default: 0
    },
    storeIn: {
      type: Number,
      default: 0
    },
    storeOut: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      defaultperson,
      checked: false,
      value: 1
    }
  },
  methods: {
    OnClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';
.item_card_sotre_wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
}
.item_desc_sub {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 34px;
  font-size: @font-size-small;
  color: #676767;
  background-color: #ffffff;
}
</style>
