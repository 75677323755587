<template>
  <div id="app">
    <!-- <transition :name="transitionName">
      <navigation>
        <router-view class="router" />
      </navigation>
    </transition> -->
    <transition :name="transitionNameTab">
      <keep-alive :include="cacheList">
        <!-- <router-view v-if="$route.meta.keepAlive" class="router" /> -->
        <router-view v-if="$route.meta.keepAlive" class="router" />
      </keep-alive>
    </transition>
    <transition :name="transitionName">
      <navigation>
        <router-view v-if="!$route.meta.keepAlive" class="router" />
      </navigation>
    </transition>
    <router-view name="tabbar" />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  data() {
    return {
      // transitionName: 'van-slide-left',
      transitionName: 'to-fix',
      // transitionNameTab: 'van-slide-left'
      transitionNameTab: 'to-right'
    }
  },
  computed: {
    ...mapGetters(['tagsList']),
    cacheList() {
      let cacheList = []
      if (this.tagsList.length) {
        cacheList = [
          ...cacheList,
          ...this.tagsList
            .filter(item => {
              return item.meta && item.meta.keepAlive
            })
            .map(item => item.name)
        ]
      }
      return cacheList
    }
  },
  watch: {
    $route(newRoute, oldRouter) {
      const { name, path, meta } = newRoute
      const { meta: meta2 } = oldRouter
      let newList = [...this.tagsList]
      let index = newList.findIndex(item => item.name === name)
      if (meta.keepAlive && index < 0) {
        // 需要缓存
        newList.push({ name, path, meta })
        this.setTagList(newList)
      }
      if (meta.tabIndex && meta2.tabIndex) {
        if (meta.tabIndex > meta2.tabIndex) this.transitionNameTab = 'to-left'
        else this.transitionNameTab = 'to-right'
      } else this.transitionNameTab = 'to-right'
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log('beforeRouteEnter')
    next()
  },
  created() {
    this.$navigation.on('forward', () => {
      this.transitionName = 'van-slide-right'
      // this.transitionName = 'to-left'
    })
    this.$navigation.on('back', () => {
      // this.transitionName = 'van-slide-left'
      this.transitionName = 'to-right'
    })
  },
  methods: {
    ...mapMutations(['setTagList'])
  }
}
</script>

<style lang="less">
#app {
  height: 100vh;
  font-family: PingFangSC-Regular;
  background-color: #f1f3f5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/** 解决路由切换动画上下闪动问题 */
.router {
  position: absolute;
  top: safe-area-inset-top;
  top: env(safe-area-inset-top);
  width: 100%;
  transition: all 0.377s ease;
  backface-visibility: hidden;
}
.to-left-leave {
  transform: translateX(0);
}

.to-left-leave-to {
  transform: translateX(-100%);
}

.to-left-enter {
  transform: translateX(100%);
}

.to-left-enter-to {
  transform: translateX(0);
}

.to-right-leave {
  transform: translateX(0);
}

.to-right-leave-to {
  transform: translateX(100%);
}

.to-right-enter {
  transform: translateX(-100%);
}

.to-right-enter-to {
  transform: translateX(0);
}

.to-fix-leave {
  transform: translateX(0);
}

.to-fix-leave-to {
  transform: translateX(0);
}

.to-fix-enter {
  transform: translateX(0);
}

.to-fix-enter-to {
  transform: translateX(0);
}
</style>
