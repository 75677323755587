<template>
  <div class="home_tab">
    <van-pull-refresh
      v-model="isLoading"
      class="fixheight"
      @refresh="onRefresh"
    >
      <div class="home_tab_user_info">
        <user-card
          ref="uc"
          :user-lv="userLv"
          :user-name="userName"
          :user-avatar="userAvatar"
          :user-join-day="userJoinDay"
          :actions="actions"
          @settingClick="settingClick"
          @actionClick="settingSelect"
        />
        <brokerage-card
          ref="icard"
          :team-fee="
            String(
              UserProfile.team_building_fee
                ? UserProfile.team_building_fee.toFixed(2)
                : '0.00'
            )
          "
          :retrun-fee="String(retrunfeec ? retrunfeec.toFixed(2) : '0.00')"
          :ing-fee="
            String(
              UserProfile.commission_ing
                ? UserProfile.commission_ing.toFixed(2)
                : '0.00'
            )
          "
          :wait-fee="
            String(
              UserProfile.commission_wait
                ? UserProfile.commission_wait.toFixed(2)
                : '0.00'
            )
          "
          @click.native="viewIncome"
        />
      </div>
      <van-list class="home_tab_info_card">
        <van-cell title="销量排行" value="">
          <template #extra>
            <span class="custom-title" @click="rankClick">
              查看更多
              <van-icon name="arrow" />
            </span>
          </template>
        </van-cell>
        <!-- <van-cell title="测试" @click="test1" /> -->
        <item-card-person
          v-if="isLoading && salesRankItem.length === 0"
          :rank="1"
          skeleton
        />
        <item-card-person
          v-if="isLoading && salesRankItem.length === 0"
          :rank="2"
          skeleton
        />
        <item-card-person
          v-if="isLoading && salesRankItem.length === 0"
          :rank="3"
          skeleton
        />
        <template v-else>
          <item-card-person
            v-for="(item, index) in salesRankItem"
            :key="index"
            :rank="index + 1"
            :name="item.name === null ? item.nickname : item.name"
            :time="formTime(new Date(item.reg_time))"
            :amount="item.sum"
            :avatar="item.head_pic"
            :type="item.type_name"
          />
        </template>
        <van-empty
          v-if="!isLoading && salesRankItem.length === 0"
          :image="emptyimage"
        />
      </van-list>
      <van-list class="home_tab_info_card">
        <van-cell title="库存预警" value="">
          <!-- <template #extra>
            <span class="custom-title" @click="InventoryClick">
              查看更多
              <van-icon name="arrow" />
            </span>
          </template> -->
        </van-cell>
        <item-card-goods-store
          v-for="(item, index) in storeWarnItem"
          :key="index"
          :goods="item"
          :store="item.store"
          :store-in="item.income"
          :store-out="item.outcome"
          class="class_item_card"
        />
        <van-empty
          v-if="storeWarnItem.length === 0"
          description="库存预警为空"
        />
      </van-list>
      <van-divider dashed>
        底线在此
      </van-divider>
    </van-pull-refresh>
  </div>
</template>

<script>
import {
  List,
  NoticeBar,
  Cell,
  PullRefresh,
  Empty,
  Toast,
  Divider,
  Skeleton
} from 'vant'

// 使用装饰器
// eslint-disable-next-line no-unused-vars
// import { confirm } from '@/decorator'
import { format, DATE_FMT } from '@/utils/date'
import asyncwait from '@/mixins/asyncwait'
import handleNetError from '@/mixins/checklogin'
import emptyimage from '../../assets/empty-image-default.png'
// eslint-disable-next-line no-unused-vars
import { loadSalesRank, getuserinfo, loadStoreWarn } from '@/api/home'
import BrokerageCard from './brokerage-card'
import UserCard from './usercard'
import itemCardPerson from '../../components/item-card-person'
import ItemCardGoodsStore from '../../components/item-card-goods-store'
import { mapState, mapActions } from 'vuex'
import store from '@/store'

export default {
  name: 'Home',
  components: {
    [List.name]: List,
    [Cell.name]: Cell,
    [Toast.name]: Toast,
    [Empty.name]: Empty,
    [Divider.name]: Divider,
    [Skeleton.name]: Skeleton,
    [NoticeBar.name]: NoticeBar,
    [PullRefresh.name]: PullRefresh,
    [BrokerageCard.name]: BrokerageCard,
    [itemCardPerson.name]: itemCardPerson,
    [ItemCardGoodsStore.name]: ItemCardGoodsStore,
    [UserCard.name]: UserCard
  },
  mixins: [asyncwait, handleNetError],
  data() {
    return {
      emptyimage: emptyimage,
      userLv: '',
      userName: '',
      userAvatar: null,
      userJoinDay: null,
      isLoading: false,
      actions: [],
      // actions: [
      //   { text: '修改密码' },
      //   { text: '打开登录' },
      //   { text: '测试1' },
      //   { text: '测试2' }
      // ],
      salesRankItem: [],
      storeWarnItem: [],
      currentDate: format(new Date(), DATE_FMT)
    }
  },
  computed: {
    ...mapState(['hasLogin']),
    retrunfeec() {
      return (
        this.UserProfile.return_commision + this.UserProfile.commission_wait
      )
    }
  },
  // watch: {
  //   '$route.path': function(newVal, oldVal) {
  //     console.log(newVal + '---' + oldVal)
  //     if (newVal === '/home' && oldVal === '/login') {
  //       console.log('欢迎')
  //     }
  //   }
  // },
  beforeRouteEnter(to, from, next) {
    // console.log('beforeRouteEnter')
    // console.log(from.name)
    if (from.name == 'login') {
      to.meta.isLogin = true
    }
    next()
  },
  created() {
    this.isFirstEnter = true
  },
  mounted() {
    // this.isLoading = true
    // this.onRefresh()
    console.log('home mounted')
    if (this.isFirstEnter) {
      console.log('mounted onRefresh')
      this.isLoading = true
      this.onRefresh()
      this.isFirstEnter = false
    }
  },
  activated() {
    console.log('home activated')
    if (this.$route.meta.isLogin || this.isFirstEnter) {
      console.log('activated onRefresh')
      this.isLoading = true
      this.onRefresh()
      this.isFirstEnter = false
      this.$route.meta.isLogin = false
    }
  },
  methods: {
    ...mapActions(['aUpdateUserProfile']),
    async initData() {
      console.log('home initData')
      // const loading = this.$loading()
      let that = this
      try {
        await this.aUpdateUserProfile()
      } catch (err) {
        this.handleNetError(err)
        console.log('err11')
        console.log(err)
        // Toast(err)
      } finally {
        // loading.close()
      }
      this.userName = this.UserProfile.name ? this.UserProfile.name : '请登录'
      this.userAvatar = this.UserProfile.head_pic
        ? this.UserProfile.head_pic
        : undefined
      this.userJoinDay = this.UserProfile.createtime
        ? format(Date(this.createtime), DATE_FMT)
        : '2020/01/01'
      this.userLv = this.UserProfile.type_name
        ? this.UserProfile.type_name
        : '0'

      console.log('获取库存预警')
      loadStoreWarn()
        .then(storeWarn => {
          console.log(storeWarn)
          if (storeWarn.status !== 1) throw storeWarn
          that.storeWarnItem = storeWarn.data
        })
        .catch(err => {
          console.log(err)
        })
      console.log('加载销量排行')
      loadSalesRank()
        .then(salesRank => {
          if (salesRank.status !== 1) throw salesRank
          that.salesRankItem = salesRank.data
          console.log(salesRank)
        })
        .catch(err => {
          console.log(err)
        })
      this.$refs.icard.update()
    },
    settingClick() {
      console.log('SettingClick')
      this.$router.push({ name: 'ChangePSW' })
    },
    formTime(v) {
      format(v, DATE_FMT)
    },
    test1() {
      console.log('test1')
      // this.$router.push({ name: 'ChangePSW' })
    },
    settingSelect(index) {
      console.log('selected')
      switch (index) {
        case 0:
          // this.$router.push({ name: 'ChangePSW' })
          this.$nextTick(() => {
            this.$router.push({ name: 'ChangePSW' })
          })
          break
        case 1:
          console.log('测试：打开登录页面')
          this.$router.push('/login')
          break
        case 2:
          console.log('测试：log取出token')
          // console.log(this.token)
          // console.log(store.getters.getAccessToken)
          store.state.loginStatus = 0
          break
        case 3:
          console.log('测试：保存token')
          this.$q(
            'token',
            // 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC93d3cuc2VuZnV0aWFueGlhLmNvbSIsImF1ZCI6Imh0dHA6XC9cL3d3dy5zZW5mdXRpYW54aWEuY29tIiwiaWF0IjoxNjEwNTg3NTEyLCJuYmYiOjE2MTA1ODc1MjIsImV4cCI6MTYxMDg0NjcxMiwiZGF0YSI6eyJpZCI6MSwidXNlcm5hbWUiOiIxNTY3NjE5MzA4NiJ9fQ.fjCeiY-ObyUayJEgQDKuag6hbR93KZf3gw6__rGQPUg'
            'eyJ0eXAiOiJKV'
          )
          break
        default:
          break
      }
      // this.$router.push({ name: 'ChangePSW' })
    },
    rankClick() {
      console.log('rankClick')
      this.$router.push({ name: 'SalesRankEntity' })
    },
    InventoryClick() {
      console.log('InventoryClick')
    },
    viewIncome() {
      console.log('viewIncome')
      this.$router.push({ name: 'incomeEntity' })
    },
    async onRefresh() {
      await this.asyncWait(1000)
      await this.initData()
      this.isLoading = false
    }
  }
}
</script>
<style lang="less" scoped>
@import '@/style/global.less';
.home_tab {
  position: relative;
  background-color: @gkcolor;
  &_user_info {
    display: block;
    height: 160px;
  }
  &_info_card {
    display: flex;
    flex-direction: column;
    margin: 12px 14px;
    margin-bottom: 10px;
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 6px;
  }
}
.fixheight {
  height: calc(100vh - 50px);
  overflow-x: hidden;
  overflow-y: scroll;
}
.custom-title {
  display: flex;
  align-items: center;
  height: 24px;
  font-family: Source Han Sans CN;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #999999;
}
</style>
