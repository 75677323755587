var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home_tab"},[_c('van-pull-refresh',{staticClass:"fixheight",on:{"refresh":_vm.onRefresh},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[_c('div',{staticClass:"home_tab_user_info"},[_c('user-card',{ref:"uc",attrs:{"user-lv":_vm.userLv,"user-name":_vm.userName,"user-avatar":_vm.userAvatar,"user-join-day":_vm.userJoinDay,"actions":_vm.actions},on:{"settingClick":_vm.settingClick,"actionClick":_vm.settingSelect}}),_c('brokerage-card',{ref:"icard",attrs:{"team-fee":String(
            _vm.UserProfile.team_building_fee
              ? _vm.UserProfile.team_building_fee.toFixed(2)
              : '0.00'
          ),"retrun-fee":String(_vm.retrunfeec ? _vm.retrunfeec.toFixed(2) : '0.00'),"ing-fee":String(
            _vm.UserProfile.commission_ing
              ? _vm.UserProfile.commission_ing.toFixed(2)
              : '0.00'
          ),"wait-fee":String(
            _vm.UserProfile.commission_wait
              ? _vm.UserProfile.commission_wait.toFixed(2)
              : '0.00'
          )},nativeOn:{"click":function($event){return _vm.viewIncome($event)}}})],1),_c('van-list',{staticClass:"home_tab_info_card"},[_c('van-cell',{attrs:{"title":"销量排行","value":""},scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('span',{staticClass:"custom-title",on:{"click":_vm.rankClick}},[_vm._v(" 查看更多 "),_c('van-icon',{attrs:{"name":"arrow"}})],1)]},proxy:true}])}),(_vm.isLoading && _vm.salesRankItem.length === 0)?_c('item-card-person',{attrs:{"rank":1,"skeleton":""}}):_vm._e(),(_vm.isLoading && _vm.salesRankItem.length === 0)?_c('item-card-person',{attrs:{"rank":2,"skeleton":""}}):_vm._e(),(_vm.isLoading && _vm.salesRankItem.length === 0)?_c('item-card-person',{attrs:{"rank":3,"skeleton":""}}):_vm._l((_vm.salesRankItem),function(item,index){return _c('item-card-person',{key:index,attrs:{"rank":index + 1,"name":item.name === null ? item.nickname : item.name,"time":_vm.formTime(new Date(item.reg_time)),"amount":item.sum,"avatar":item.head_pic,"type":item.type_name}})}),(!_vm.isLoading && _vm.salesRankItem.length === 0)?_c('van-empty',{attrs:{"image":_vm.emptyimage}}):_vm._e()],2),_c('van-list',{staticClass:"home_tab_info_card"},[_c('van-cell',{attrs:{"title":"库存预警","value":""}}),_vm._l((_vm.storeWarnItem),function(item,index){return _c('item-card-goods-store',{key:index,staticClass:"class_item_card",attrs:{"goods":item,"store":item.store,"store-in":item.income,"store-out":item.outcome}})}),(_vm.storeWarnItem.length === 0)?_c('van-empty',{attrs:{"description":"库存预警为空"}}):_vm._e()],2),_c('van-divider',{attrs:{"dashed":""}},[_vm._v(" 底线在此 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }