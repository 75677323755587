import { get, post } from '@/utils/request'

/**
 * 获取经销商配送订单列表
 * @param {Sting}} data 参数
 */
export const deliveryList = (data = {}) => {
  return get('deliveryList', data)
}

/**
 * 经销商派送
 * @param {Sting}} data 参数
 */
export const outSend = (data = {}) => {
  return post('outSend', data)
}
