<template>
  <div class="class_user_card">
    <div class="class_user_card_avatar">
      <van-image
        round
        class="class_user_card_avatar_img"
        fit="contain"
        :src="tavatar"
        @error="imgerror"
      />
    </div>
    <div class="class_user_card_main">
      <div class="class_user_card_main_name">
        {{ userName }}
      </div>
      <div class="class_user_card_main_lv">
        {{ userLv }}
      </div>
    </div>
    <div class="class_user_card_sub">
      <!-- <div class="class_user_card_sub_btn">
        <van-icon name="chat-o" />
        设置
      </div> -->
      <van-popover
        v-model="showPopover"
        placement="bottom-end"
        :actions="actions"
        @select="onSelect"
      >
        <template #reference>
          <van-button
            ref="settingbtn"
            icon="setting"
            plain
            round
            hairline
            type="default"
            class="class_user_card_sub_btn"
            @click="btnClick"
          >
            设置
          </van-button>
        </template>
      </van-popover>
      <div class="class_user_card_sub_time">
        {{ '加入时间：' + userJoinDay }}
      </div>
    </div>
  </div>
</template>

<script>
import defaultperson from '../../assets/avatar_default.png'
import { Popover, Image, Button } from 'vant'
export default {
  name: 'UserCard',
  components: {
    [Popover.name]: Popover,
    [Button.name]: Button,
    [Image.name]: Image
  },
  props: {
    userName: {
      type: String,
      default: ''
    },
    userLv: {
      type: String,
      default: ''
    },
    userAvatar: {
      type: String,
      default: defaultperson
    },
    userJoinDay: {
      type: String,
      default: '2020/01/01'
    },
    actions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      avatar: null,
      // actions: [{ text: '选项一' }, { text: '选项二' }, { text: '选项三' }],
      showPopover: false
    }
  },
  computed: {
    tavatar() {
      return this.avatar !== null ? this.avatar : this.userAvatar
    }
  },
  methods: {
    btnClick() {
      if (this.actions.length !== 0) {
        // console.log(this.actions)
        this.showPopover = true
      }
      this.$emit('settingClick')
    },
    onSelect(e, i) {
      // this.showPopover = false
      console.log('onSelect')
      this.$emit('actionClick', i)
    },
    imgerror() {
      this.avatar = defaultperson
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';
.class_user_card {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;
  margin: 14px;
  &_avatar {
    flex: 0 0 65px;
    width: 65px;
  }
  &_main {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 8px;
    &_name {
      max-width: 140px;
      height: 31px;
      overflow: hidden;
      font-family: Source Han Sans CN;
      font-size: 18px;
      font-weight: 400;
      line-height: 31px;
      color: #333333;
      text-overflow: ellipsis;
      white-space: nowrap;
      opacity: 1;
    }
    &_lv {
      max-width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &_sub {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    &_btn {
      width: 80px;
      height: 30px;
      font-family: Source Han Sans CN;
      font-size: 14px;
      font-weight: 400;
      background: #ffffff;
      border-radius: 99px;
      box-shadow: 0 1px 9px rgba(0, 40, 87, 0.16);
    }
    &_time {
      font-family: Source Han Sans CN;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: #999999;
    }
  }
}
</style>
