<template>
  <div class="content_login">
    <!-- <md-field-group>
      <md-field
        v-model="account"
        v-validate="'required'"
        icon="username"
        placeholder="请输入账号"
        right-icon="clear"
        name="user"
        data-vv-as="帐号"
        class="r-round"
        @right-click="clearText"
      />
      <md-field
        v-model="password"
        v-validate="'required'"
        icon="lock"
        placeholder="请输入密码"
        :type="visiblePass ? 'text' : 'password'"
        :right-icon="visiblePass ? 'eye-open' : 'eye-close'"
        data-vv-as="密码"
        class="r-round"
        name="password"
        @right-click="visiblePass = !visiblePass"
      /> -->
    <van-form
      class="class_form"
      validate-first
      :show-error-message="false"
      @submit="loginSubmit"
      @failed="onFailed"
    >
      <van-field
        v-model="account"
        class="class_field r-round"
        clearable
        name="acount"
        left-icon="manager"
        placeholder="请输入账号"
        :rules="[{ required: true, message: '请输入账号' }]"
      >
        <template #left-icon>
          <van-icon name="username" class-prefix="my-icon" />
        </template>
      </van-field>
      <van-field
        v-model="password"
        class="class_field r-round"
        name="password"
        left-icon="lock"
        :type="visiblePass ? 'text' : 'password'"
        placeholder="请输入密码"
        :rules="[{ required: true, message: '请输入密码' }]"
        @click-right-icon="visiblePass = !visiblePass"
      >
        <template #right-icon>
          <van-icon
            :name="visiblePass ? 'eye-open' : 'eye-close'"
            class-prefix="my-icon"
          />
        </template>
      </van-field>
      <div class="class_checkbox">
        <van-checkbox
          v-model="cfg['saveLogin']"
          shape="square"
          @change="saveCfg"
        >
          保存密码
        </van-checkbox>
        <van-checkbox
          v-model="cfg['autologin']"
          shape="square"
          @change="saveCfg"
        >
          自动登录
        </van-checkbox>
      </div>
      <van-button
        size="large"
        round
        type="primary"
        native-type="submit"
        :loading="isLogining"
        class="class_loginbtn"
      >
        登录
      </van-button>
    </van-form>
    <!-- </md-field-group> -->
  </div>
</template>

<script>
import field from '@/components/field/'
import fieldGroup from '@/components/field-group/'
import Base64 from '@/utils/base64'
// eslint-disable-next-line no-unused-vars
import { postLogin, getuserinfo, getDemoData } from '@/api/login'
// eslint-disable-next-line no-unused-vars
import {
  Popup,
  Button,
  Toast,
  Checkbox,
  CheckboxGroup,
  Notify,
  Field,
  Form
} from 'vant'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'LoginRequest',

  components: {
    [field.name]: field,
    [Button.name]: Button,
    [Form.name]: Form,
    [fieldGroup.name]: fieldGroup,
    [Field.name]: Field,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Popup.name]: Popup
  },

  data() {
    return {
      account: '',
      password: '',
      cfg: { autologin: false, saveLogin: false },
      visiblePass: false,
      showKefu: false,
      isLogining: false
    }
  },
  computed: {
    ...mapState(['checkLoginFlag'])
  },
  created() {},
  mounted() {
    this.initData()
  },
  activated() {
    console.log('login activated')
    setTimeout(() => {
      console.log('al:', this.cfg['autologin'])
      if (this.cfg['autologin'] && this.checkLoginFlag) {
        Toast('正在自动登陆')
        this.setCheckLogin(false)
        setTimeout(() => {
          this.loginSubmit()
        }, 2000)
      }
    }, 1000)
  },
  methods: {
    ...mapActions(['aUpdateUserProfile', 'loginAction']),
    ...mapMutations(['setTagList', 'setCheckLogin']),
    initData() {
      this.cfg = { ...this.cfg, ...this.configs }
      try {
        if (this.cfg.saveLogin) {
          console.log('loadsavedLogin')
          this.account = this.logindata.user
          this.password = Base64.decode(this.logindata.psw)
        }
      } catch (err) {
        console.log(err)
        // this.account = ''
        // this.password = ''
      }
    },
    saveCfg() {
      this.$q('configs', this.cfg)
      if (this.cfg.saveLogin === false) {
        this.$q('logindata', {
          user: '',
          psw: ''
        })
      }
    },
    clearText() {
      this.account = ''
    },

    // async validate() {
    //   const result = await this.$validator.validate()
    //   if (!result) {
    //     console.log(result)
    //     console.log(this.errorBags)
    //     const errMsg = this.errorBags.items[0].msg
    //     Toast(errMsg)
    //     throw new Error(`表单验证: ${errMsg}`)
    //   }
    // },
    onFailed(errorInfo) {
      console.log('failed', errorInfo)
      Notify({
        message: `${errorInfo.errors[0].message}`
      })
    },
    async login() {
      const loginData = { username: this.account, password: this.password }
      console.log(loginData)
      const data = await postLogin(loginData)
      // Notify({
      //   message: '数据加载成功',
      //   type: 'success'
      // })
      console.log(data)
      if (data.status === 1) {
        console.log('登陆成功')
        // this.$q('token', data.data.token)
        this.setTagList([])
        this.loginAction({
          token: data.data.token,
          flag: this.cfg['autologin']
        })
      } else {
        throw { name: 'acountError', message: data.msg }
      }
    },

    async savelogin() {
      if (this.configs.saveLogin) {
        this.$q('logindata', {
          user: this.account,
          psw: Base64.encode(this.password)
        })
      }
    },

    async loginSubmit() {
      this.isLogining = true
      // const loading = this.$loading()
      try {
        // await this.validate()
        await this.login()
        await this.savelogin()
        await this.getUserProfile()
      } catch (err) {
        console.log(err)
        if (err.name === 'acountError') {
          Notify({
            message: `登陆失败：${err.message}`
          })
        } else {
          Notify({
            message: '登陆失败：未知错误'
          })
          this.$Sentry.captureException(err)
        }
      } finally {
        this.isLogining = false
        // loading.close()
      }
    },

    async getUserProfile() {
      console.log('getUserProfile')
      // home页面会获取用户信息，这里不再获取
      // const res = await this.aUpdateUserProfile()
      // console.log(res)

      // setLocalStorage({
      //   avatar: data.avatar,
      //   user_id: data.user_id,
      //   background_image: data.background_image,
      //   nick_name: data.nick_name
      // })

      this.routerRedirect()
    },

    routerRedirect() {
      const { query } = this.$route
      this.$router.replace({
        name: query.redirect || 'Home',
        query: query
      })
    }
  }
}
</script>

<style lang="less" scoped>
.class_form {
  padding-right: 15px;
  padding-left: 15px;
}
.class_field {
  margin-bottom: 15px;
  border: 1px solid gray;
  border-radius: 50px;
}
.class_field:last-child {
  margin-bottom: 0;
}
.class_checkbox {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-left: 100px;
  margin: 12px;
}
.class_loginbtn {
  margin-top: 10px;
}
.r-round {
  border-radius: 25px;
}
.r-round:focus-within {
  border-color: #30ae64;
}
.register {
  padding-top: 40px;
}
.register a {
  color: gray;
}
.register > div {
  box-sizing: border-box;
  width: 50%;
  padding: 0 20px;
}
</style>
