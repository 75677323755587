// import asyncLoader from 'core/async-loader'

// const Tabbar = () =>
//   import(/* webpackChunkName: "Tabbar" */ '@/vue/components/Tabbar/')

import Tabbar from '../../components/Tabbar'
// import Inventory from '../../views/inventory/inventory'
const Inventory = () => import('../../views/inventory/inventory')
// import DeliveEntity from '../../views/inventory/delive'
const DeliveEntity = () => import('../../views/inventory/delive')
// import SelectGoods from '../../views/inventory/delive/selectgoods'
const SelectGoods = () => import('../../views/inventory/delive/selectgoods')
// import InvDetailEntity from '../../views/inventory/detail'
const InvDetailEntity = () => import('../../views/inventory/detail')

export default [
  {
    path: '/inventory',
    name: 'Inventory',
    meta: {
      login: true,
      keepAlive: true,
      tabIndex: 2,
      title: '库存'
    },
    components: { default: Inventory, tabbar: Tabbar }
  },
  {
    path: '/inventory/detail',
    name: 'invDetailEntity',
    component: InvDetailEntity,
    props: route => route.query,
    meta: {
      title: '库存详情'
    }
  },
  {
    path: '/inventory/delive',
    name: 'deliveEntity',
    component: DeliveEntity,
    meta: {
      title: '出库'
    }
  },
  {
    path: '/inventory/delive/select',
    name: 'selectGoods',
    component: SelectGoods,
    meta: {
      title: '选择商品'
    }
  }
]
// export default [
//   {
//     path: '/items',
//     name: 'class',
//     meta: {
//       keepAlive: true
//     },
//     components: {
//       default: asyncLoader('items/tabbar-class'),
//       tabbar: Tabbar
//     }
//   },
//   {
//     path: '/items/search',
//     name: 'search',
//     meta: {
//       keepAlive: true
//     },
//     component: asyncLoader('items/search')
//   },
//   {
//     path: '/items/search/result',
//     name: 'search-result',
//     meta: {
//       keepAlive: true
//     },
//     component: asyncLoader('items/search-result'),
//     props: route => route.query
//   },
//   {
//     path: '/items/detail/:itemId',
//     name: 'detail',
//     props: true,
//     component: asyncLoader('items/detail')
//   },
//   {
//     path: '/items/list',
//     name: 'list',
//     component: asyncLoader('items/list'),
//     props: route => ({
//       itemClass: +route.query.itemClass
//     })
//   }
// ]
