<template>
  <div class="brokerage_card_warp">
    <div class="brokerage_card_warp_item">
      <div class="brokerage_card_warp_item_main">
        <span id="num1">{{ retrunFee }}</span>
      </div>
      <div class="brokerage_card_warp_item_sub">
        累计佣金
      </div>
    </div>
    <div class="brokerage_card_warp_item">
      <div class="brokerage_card_warp_item_main">
        <span id="num2">{{ ingFee }}</span>
      </div>
      <div class="brokerage_card_warp_item_sub">
        实时佣金
      </div>
    </div>
    <div class="brokerage_card_warp_item">
      <div class="brokerage_card_warp_item_main">
        <span id="num3">{{ teamFee }}</span>
      </div>
      <div class="brokerage_card_warp_item_sub">
        本月团建金额
      </div>
    </div>
    <div class="brokerage_card_warp_item">
      <div class="brokerage_card_warp_item_main">
        <span id="num4">{{ waitFee }}</span>
      </div>
      <div class="brokerage_card_warp_item_sub">
        待结算佣金
      </div>
    </div>
  </div>
</template>
<script>
import countup from '@/mixins/mixcountup'
export default {
  name: 'BrokerageCard',
  mixins: [countup],
  props: {
    retrunFee: {
      type: String,
      default: '0.00'
    },
    ingFee: {
      type: String,
      default: '0.00'
    },
    teamFee: {
      type: String,
      default: '0.00'
    },
    waitFee: {
      type: String,
      default: '0.00'
    }
  },
  data() {
    return {
      retrunFee1: this.retrunFee,
      ingFee1: this.ingFee,
      teamFee1: this.teamFee,
      waitFee1: this.waitFee
    }
  },
  watch: {
    retrunFee(newVal, oldVal) {
      console.log('watch retrunFee:', newVal, oldVal)
      this.numcountup('num1', newVal, oldVal)
      this.retrunFee1 = newVal
    },
    ingFee(newVal, oldVal) {
      console.log('watch ingFee:', newVal, oldVal)
      this.numcountup('num2', newVal, oldVal)
      this.ingFee1 = newVal
    },
    teamFee(newVal, oldVal) {
      console.log('watch teamFee:', newVal, oldVal)
      this.numcountup('num3', newVal, oldVal)
      this.teamFee1 = newVal
    },
    waitFee(newVal, oldVal) {
      console.log('watch waitFee:', newVal, oldVal)
      this.numcountup('num4', newVal, oldVal)
      this.waitFee1 = newVal
    }
  },
  methods: {
    update() {
      console.log('bc update')
      this.retrunFee1 = this.retrunFee
      this.ingFee1 = this.ingFee
      this.teamFee1 = this.teamFee
      this.waitFee1 = this.waitFee
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';
.brokerage_card_warp {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  height: 62px;
  padding-top: 10px;
}
.brokerage_card_warp_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  &_main {
    font-size: @font-size-bigger;
    color: #ff6e21;
  }
  &_sub {
    font-size: @font-size-small;
    color: #9a9a9a;
  }
}
</style>
