<template>
  <div class="store_header">
    <div class="store_avatar">
      <img
        src="../../assets/avatar_default.png"
        alt="头像"
        class="store_avatar_img"
      />
    </div>
    <div class="store_name"></div>
  </div>
</template>

<script>
export default {
  name: 'LoginHeader'
}
</script>

<style lang="less" scoped>
.store_header {
  padding: 42px 0;
  text-align: center;
}
.store_avatar img {
  border-radius: 50%;
  &_img {
    width: 100px;
    height: 100px;
  }
}
.store_name {
  padding-top: 5px;
  font-size: 16px;
}
</style>
