import { get, post } from '@/utils/request'

export const getDemoData = (params = {}) => {
  return get('home/demo/api', params)
  // return new Promise(resolve => {
  //   setTimeout(() => {
  //     resolve({
  //       success: true,
  //       data: {
  //         name: '11',
  //       }
  //     })
  //   }, 2000)
  // })
}

/**
 * 获取用户信息
 * @param {Sting}} params 参数
 */
export const getuserinfo = (params = {}) => {
  return get('getuserinfo', params)
}

/**
 * 获取库存预警接口
 * @param {Sting}} params 参数
 */
export const loadStoreWarn = (params = {}) => {
  return get('loadStoreWarn', params)
}

/**
 * 修改密码接口
 * @param {Sting}} data 参数
 */
export const updatepassword = (data = {}) => {
  return post('updatepassword', data)
}

/**
 * 加载销量排行
 * @param {Sting}} data 参数
 */
export const loadSalesRank = (
  params = {
    pageSize: 3,
    pageIndex: 1
  }
) => {
  return get('loadSalesRank', params)
}

/**
 * 佣金收入列表
 * @param {Sting}} data 参数
 */
export const incomeDetail = (data = {}) => {
  return get('incomeDetail', data)
}

/**
 * 团队建设申请和修改
 * @param {Sting}} data 参数
 */
export const teamBuildLog = (data = {}) => {
  return post('teamBuildLog', data)
}

/**
 * 团建申请记录
 * @param {Sting}} params 参数
 */
export const teamBuildLogList = (params = {}) => {
  return get('teamBuildLogList', params)
}

/**
 * 图片上传接口
 * @param {Sting}} data 参数
 */
export const imgUpload = (data = {}) => {
  return post('imgUpload', data, { timeout: 20000 })
}

/**
 * 团建记录详情
 * @param {Sting}} params 参数
 */
export const teamBuildLogDetail = (params = {}) => {
  return get('teamBuildLogDetail', params)
}

/**
 * 申请团建金额和上传团建图片接口
 * @param {Sting}} data 参数
 */
export const teamBuildLogAgain = (data = {}) => {
  return post('teamBuildLogAgain', data)
}
