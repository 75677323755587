<template>
  <div class="delivery_item_list">
    <van-tabs v-model="tabActive" animated swipeable>
      <van-tab
        v-for="(tab, tabIndex) in tabsItem"
        :key="tab.type"
        :title="tab.name"
        :badge="tab.badge"
      >
        <van-pull-refresh
          v-model="tab.refreshing"
          class="fixheight"
          @refresh="onRefresh(tabIndex, tab.sort)"
        >
          <van-list
            :ref="'tabScrolls' + tabIndex"
            v-model="tab.loading"
            :title="tab.name"
            :badge="tab.badge"
            :finished="tab.finished"
            finished-text="没有更多了"
            :error.sync="tab.error"
            error-text="请求失败，点击重新加载"
            offset="100"
            @load="onListLoad(tabIndex, tab.sort)"
          >
            <!-- <item-card-delivery
              class="class_item_card"
              :status="2"
              address="
                ffasffasdfsadf飞洒范德萨发大水打发士大夫士大夫撒发生发射点法发
              "
              @Click="deliveryClick(0)"
              @btnClick="dispatchClick(0)"
            /> -->
            <item-card-delivery
              v-for="(item, index) in tab.items"
              :key="index"
              :title="item.order_sn"
              :status="item.status"
              :goods-array="item.goods"
              :address="`${item.region} ${item.address}`"
              :consignee="item.consignee"
              :phone="item.mobile"
              class="class_item_card"
              @Click="deliveryClick(item)"
              @btnClick="dispatchClick(item)"
            />
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { List, Search, Tab, PullRefresh, Tabs } from 'vant'
import ItemCardDelivery from '../../components/item-card-delivery'
// eslint-disable-next-line no-unused-vars
import { deliveryList } from '@/api/delivery'
import asyncwait from '@/mixins/asyncwait'
import handleNetError from '@/mixins/checklogin'
export default {
  name: 'Delivery',
  components: {
    [List.name]: List,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Search.name]: Search,
    [PullRefresh.name]: PullRefresh,
    [ItemCardDelivery.name]: ItemCardDelivery
  },
  mixins: [asyncwait, handleNetError],
  data() {
    return {
      transitionName: 'van-slide-right',
      tabActive: 0,
      sort: this.sortVal,
      tabsItem: [
        {
          name: '全部',
          sort: {},
          badge: null,
          loading: false,
          finished: false,
          error: false,
          pageIndex: 1,
          refreshing: false,
          items: []
        },
        {
          name: '未派送',
          badge: null,
          loading: false,
          finished: false,
          error: false,
          pageIndex: 1,
          refreshing: false,
          sort: { status: 0 },
          items: []
        },
        // {
        //   name: '派送中',
        //   badge: 22,
        //   loading: false,
        //   finished: false,
        //   error: false,
        //   pageIndex: 1,
        //   refreshing: false,
        //   sort: { status: 1 },
        //   items: []
        // },
        {
          name: '已派送',
          badge: null,
          loading: false,
          finished: false,
          error: false,
          pageIndex: 1,
          refreshing: false,
          sort: { status: 1 },
          items: []
        }
      ],
      filterItemShow: false
    }
  },
  computed: {
    sortVal() {
      const { tabActive: i } = this
      return this.tabsItem[i].sort
    }
  },
  created() {},
  // beforeRouteEnter(to, from, next) {
  //   console.log('beforeRouteEnter')
  //   console.log(from.name)
  //   next()
  // },
  methods: {
    toggleFilterModal(status) {
      this.filterItemShow = status
    },
    deliveryClick(item) {
      console.log('deliveryClick')
      this.$router.push({
        name: 'deliveryDetail',
        params: { id: `${item.id}`, detail: item }
      })
    },
    dispatchClick(item) {
      console.log('dispatchClick')
      this.$router.push({
        name: 'dispatch',
        params: { id: `${item.id}`, detail: item }
      })
    },
    async onRefresh(tabIndex, param) {
      console.log(`onRefresh${tabIndex}`)
      // 清空列表数据
      this.tabsItem[tabIndex].items = []
      this.tabsItem[tabIndex].finished = false
      this.tabsItem[tabIndex].refreshing = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.tabsItem[tabIndex].loading = true
      this.tabsItem[tabIndex].pageIndex = 1
      await this.onListLoad(tabIndex, param)
    },
    async onListLoad(tabIndex, param) {
      console.log(`callload${tabIndex}`)
      await this.asyncWait(300)
      try {
        const res = await deliveryList({
          pageIndex: this.tabsItem[tabIndex].pageIndex,
          pageSize: 5,
          ...param
        })
        if (res.status !== 1) throw res
        console.log(res)
        this.tabsItem[1].badge = res.data.status0_count
        this.tabsItem[tabIndex].pageIndex++
        this.tabsItem[tabIndex].items = [
          ...this.tabsItem[tabIndex].items,
          ...res.data.list
        ]
        this.tabsItem[tabIndex].loading = false
        if (res.data.list.length < 5) {
          this.tabsItem[tabIndex].finished = true
        }
        console.log('teamBuildLogList cp')
      } catch (err) {
        this.handleNetError(err)
        this.tabsItem[tabIndex].loading = false
        this.tabsItem[tabIndex].error = true
        console.log(err)
      }
    }
  }
}
</script>

<style lang="less">
@import '@/style/global.less';
.delivery_item_list .van-tab .van-info {
  background-color: #ff9d17;
  transform: translate(100%, -30%);
}
</style>

<style lang="less" scoped>
@import '@/style/global.less';
.fixheight {
  height: calc(100vh - 44px - 50px);
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
